<template lang="pug">
    section.content
        div.container-fluid
            div.row
                div.col-md-6
                    div.card.card-primary
                        div.card-header
                            h3.card-title Editar  Examen
                        form(role="form")
                            div.card-body
                                div.form-group
                                    label(for="sCourse") Nombre del Examen
                                    input.form-control(type="text" id="sCourse" v-model="model.sExamName" placeholder="Ingrese el nombre del curso")

                                div.form-group
                                    label(for="sTime") Tiempo para contestar (en minutos)
                                    input.form-control(type="number" id="sTime" v-model="model.iTimeToAnswer" placeholder="Tiempo para reolver el cuestionario")
                                div.form-group
                                    label(for="sAprobePercent") Procentaje de Aprobación
                                    input.form-control(type="text" id="sAprobePercent" v-model="model.iPassPercent" placeholder="Procentaje de aprobación")
                            div.card-footer
                                button.btn.btn-primary(type="button" @click="Save") Guardar
                                router-link.btn.btn-primary(to="/content/exams" ) Regresar

                                div.form-group
                                    label(for="zipFile") Zip con los proyectos Active Presenter
                                    div.input-group
                                        div.custom-file
                                            input.custom-file-input(type="file" ref="file" id="zipFile" v-on:change="handleFileUpload()")
                                            label.custom-file-label(for="zipFile" id="zipLabel") Choose file
                            div.card-body                               
                                div.form-group
                                    label(for="BackImage") Imagen de fondo para Certificados
                                    div.input-group
                                        div.custom-file
                                            input.custom-file-input(type="file" ref="file2" id="BackImage" v-on:change="handleFileUpload2()")
                                            label.custom-file-label(for="BackImage" id="backLabel") Choose file
                                        img.img-fluid(:src="model.sImagePath" style="width: 100px; padding-left:5px")

                                div.form-group
                                    label(for="BadgeOn") Imagen de Insignia Activa
                                    div.input-group
                                        div.custom-file
                                            input.custom-file-input(type="file" ref="file3" id="BadgeOn" v-on:change="handleFileUpload3()")
                                            label.custom-file-label(for="BadgeOn" id="badgeOnLabel") Choose file
                                        img.img-fluid(:src="model.sBadgeOn" style="width: 100px; padding-left:5px")                                            

                                div.form-group
                                    label(for="BadgeOff") Imagen de Insignia Inactiva
                                    div.input-group
                                        div.custom-file
                                            input.custom-file-input(type="file" ref="file4" id="BadgeOff" v-on:change="handleFileUpload4()")
                                            label.custom-file-label(for="BadgeOff" id="badgeOffLabel") Choose file
                                        img.img-fluid(:src="model.sBadgeOff" style="width: 100px; padding-left:5px")





</template>
<script>
/* eslint-disable */
export default {
    name: 'ExamEdith',
    props: ['idExamPool'],
    components: {
    },    
    data() {
        return {
            file: '',
            file2: '',
            file3: '',
            file4: '',
            model: {
                bActive: false,
                sBadgeOff: '',
                sBadgeOn: '',
                sExamName: '',
                sImagePath: '',
                iPassPercent: 0,
                iTimeToAnswer: 0
            },
            model2: {
                idExamPool: 0,
                examPaths: [],
                sParentFolder: ''
            },
        }
    },
    methods: {
        getData() {
            axios.get(apiURL + 'Exam/getExamDataForUpdate?idExamPool='+ this.idExamPool, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.model = response.data.examData
                this.model.sImagePath = '/assets/img2/backgrounds/' + response.data.examData.sImagePath + '.png'
                console.log(this.model)
            } )
            .catch( (error)  => {
                console.log("error")
            } )

        },
        async Save() {

            await axios.post(apiURL + 'Exam/updateExamData', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire('Datos Guardados correctamente')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },
        async handleFileUpload(){
            this.file = this.$refs.file.files[0];
            if (this.file.type.split("/").pop() != 'x-zip-compressed') {
                Swal.fire('Tipo de archivo inválido, por favor elija un archivo .zip con la estructura solicitada')
                return
            }

            let sFolderParent = ''
            var flag = false


            await Swal.fire({
            title: 'Reiniciar proyectos de Active Presenter',
            html: "Este proceso elimina la actual carpeta que contiene los proyectos de Active Presenter y elimina la asignación de estos a las preguntas del examen. Una vez hecho este proceso no hay manera de recuperar ningún dato y/o asignación.<br/>¿Deseas continuar con este proceso?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Actualiza los proyectos de Active Presenter',
            cancelButtonText: 'No, Cancelar el proceso'
            }).then((result) => {
                if (result.isConfirmed) {
                    flag = true
                }
            })            

            if(flag)
            {
                flag = true
                var message = ''
                this.openLoading('Reiniciando Registros ...');
                await axios.post(apiURL + 'Exam/resetExamACProjects?idExamPool=' + this.idExamPool , null, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
                .then( (response) => {
                    Swal.close();
                    if(response.data.bIsCorrect == false){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.sErrors[0],
                            footer: ''
                        })
                        flag = false
                        message += 'Reiniciar Registros: '+ response.data.sErrors[0] +'<br/>'
                        return
                    }
                    sFolderParent = response.data.sParentFolder
                    Swal.fire('Base de datos reiniciada correctamente')
                } )
                .catch( (error) => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                    flag = false
                    message += 'Reiniciar Registros: ' + error + '<br/>'                    
                } )


                if (sFolderParent != '') {
                    this.openLoading('Eliminando Archivos ...');
                    let bodyData = new FormData();
                    bodyData.append('fileName', sFolderParent);
                    bodyData.append('directory', 1);
                    await axios.post('https://sistema.etciberoamerica.com/api/remove-file-insigne', bodyData, { headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
                    } })
                    .then( (response) => {
                        if (response.data.status == true) {

                            //this.model.sImagePath = this.file2.name
                            Swal.fire('Estructura de proyectos de Active Presenter eliminada')
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.data.message,
                                footer: ''
                            })
                            flag = false
                            message += 'Eliminar Carpeta de Proyectos: '+ response.data.message +'<br/>' 
                        }
                    } )
                    .catch( (error) => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error,
                            footer: ''
                        })
                        flag = false
                        message += 'Eliminar Carpeta de Proyectos: '+ error +'<br/>'                        
                    } )
                }

                this.openLoading('Procesando Preguntas de Active Presenter ...');
                let formData = new FormData();
                let dir = []
                formData.append('file', this.file);            
                await axios.post('https://sistema.etciberoamerica.com/api/upload-file-insigne/exam', formData, { headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
                } })
                .then( (response) => {
                    if (response.data.status == true) {
                        
                        for (let index = 0; index < response.data.directories.length; index++) {
                            let directory = {
                                'idExamPaths': 0,
                                'sProjectPath': '../../../assets/' + response.data.parentFolder + response.data.directories[index] + '/practice.html',
                                'sFolderName': response.data.directories[index]
                            }
                            dir.push(directory)
                        }
                        this.model2.sParentFolder = response.data.parentFolder
                    }
                    else{
                        console.log(response.data.message)
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.message,
                            footer: ''
                        })
                        flag = false
                        message += 'Procesar Nuevo Archivo .zip: '+ response.data.message +'<br/>'                             
                    }
                } )
                .catch( (error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                    flag = false
                    message += 'Procesar Nuevo Archivo .zip: '+ error +'<br/>'                    
                } )
                Swal.close();            

                this.model2.idExamPool = this.idExamPool;
                this.model2.examPaths = dir


                this.openLoading('Registrando Proyectos ...');
                await axios.post(apiURL + 'Exam/AddExamPaths', this.model2, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
                .then( (response) => {
                    Swal.close();
                    if(response.data.bIsCorrect == false){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.sErrors[0],
                            footer: ''
                        })
                        flag = false
                        message += 'Registrar Proyectos en la Base de Datos: '+ response.data.sErrors[0] +'<br/>'                        
                        return
                    }
                    Swal.fire('Proyectos de Active Presenter registrados correctamente')
                } )
                .catch( (error) => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                    flag = false
                    message += 'Registrar Proyectos en la Base de Datos: '+ error +'<br/>'                    
                } )

                if (!flag) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: 'El proceso ha terminado con los siguientes errores:<br/><br/>' + message,
                        footer: ''
                    })
                }

            }

        },
        handleFileUpload2(){
            this.file2 = this.$refs.file2.files[0];
            if (this.file2.type.split("/").pop() != 'png') {
                Swal.fire('Tipo de archivo inválido, por favor elija un archivo de imagen .png')
                return
            }


            document.getElementById("backLabel").innerHTML = this.$refs.file2.files[0].name
            
            this.openLoading('Procesando Imagen de fondo de Certificados ...');
            let formCert = new FormData();
            formCert.append('file', this.file2);
            axios.post('https://sistema.etciberoamerica.com/api/upload-file-insigne/background', formCert, { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
            } })
            .then( (response) => {
                if (response.data.status == true) {
                    let model2 = {
                        sImagePath: this.file2.name,
                        sImageType: 'Background',
                        idExamPool: this.idExamPool
                    }
                    axios.post(apiURL + 'Exam/updateImagePath', model2, { headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.userToken
                    } })
                    .then( (response) => {
                        Swal.close();
                        if(response.data.bIsCorrect == false){
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.data.sErrors[0],
                                footer: ''
                            })
                            return
                        }
                        Swal.fire('Datos Guardados correctamente')
                        this.getData()
                    } )
                    .catch( (error) => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error,
                            footer: ''
                        })
                    } )                    

                    //this.model.sImagePath = this.file2.name
                    Swal.fire('Imagen de Fondo Procesada Correctamente!!')
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        footer: ''
                    })
                }
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },


        handleFileUpload3(){
            this.file3 = this.$refs.file3.files[0];
            if (this.file3.type.split("/").pop() != 'png') {
                Swal.fire('Tipo de archivo inválido, por favor elija un archivo de imagen .png')
                return
            }
            document.getElementById("badgeOnLabel").innerHTML = this.$refs.file3.files[0].name

            this.openLoading('Procesando Insignia Activa ...');
            let formBadge1 = new FormData();
            formBadge1.append('file', this.file3);
            axios.post('https://sistema.etciberoamerica.com/api/upload-file-insigne/badge', formBadge1, { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
            } })
            .then( (response) => {
                if (response.data.status == true) {
                    let model3 = {
                        sImagePath: '/assets/img2/insignias/' + this.file3.name,
                        sImageType: 'BadgeOn',
                        idExamPool: this.idExamPool
                    }
                    axios.post(apiURL + 'Exam/updateImagePath', model3, { headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.userToken
                    } })
                    .then( (response) => {
                        Swal.close();
                        if(response.data.bIsCorrect == false){
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.data.sErrors[0],
                                footer: ''
                            })
                            return
                        }
                        Swal.fire('Datos Guardados correctamente')
                        this.getData()
                    } )
                    .catch( (error) => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error,
                            footer: ''
                        })
                    } )                    
                    //this.model.sBadgeOn = '/assets/img2/insignias/' + this.file3.name
                    Swal.fire('Imagen de Insignia Activa Procesada Correctamente!!')
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        footer: ''
                    })
                }
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )




        },
        handleFileUpload4(){
            this.file4 = this.$refs.file4.files[0];
            if (this.file4.type.split("/").pop() != 'png') {
                Swal.fire('Tipo de archivo inválido, por favor elija un archivo de imagen .png')
                return
            }
            document.getElementById("badgeOffLabel").innerHTML = this.$refs.file4.files[0].name

            this.openLoading('Procesando Insignia Inactiva ...');
            let formBadge2 = new FormData();
            formBadge2.append('file', this.file4);
            axios.post('https://sistema.etciberoamerica.com/api/upload-file-insigne/badge', formBadge2, { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzQwODNjZmU4NzU3ZWMyY2MwZTA0MzNiM2U5MTQ3Y2U2ZDg0ZGVjMjFhNWU2Mzg4ZjAyNmFlNWQ0MWQ2YzcwNDZkODgwZDM4OWExMTUzNGQiLCJpYXQiOjE2NTE2NzY1NzUsIm5iZiI6MTY1MTY3NjU3NSwiZXhwIjoxNjgzMjEyNTc1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.7H7LbxJ6F2RMoD2kmuPglpPNReTGvwmVxzP7ThZ8iTjbO3_EG8dkpbgMLSC_p3Ve8bEVCKh77U8mrH37YdN7Ff4dS3_zMs0LYAA2739tFK4jij8l0g5kE0tSpnNgXWiEhIrImTn4IfFJ-DuCLlRPqBFK1_lX4liRooWbDUbHEhJ2979xhee4Y26w1t8luGMBRz5fkEL0dkfrCKw50m6Ov6GPqV59mmmf75rnEGGwm5SiureRDrtpzQtc7-cTBd9RNlV2Jvds3x4ALaVS2k5PiSP-YNrWXxXiRLPlORZ-9KugW_FV8aMdEuBf81tlYNA9N_QRdynnyiB7GGg2LMnh61yHSzwrSwb1KCM4dXy1RDLyguxF6BwJHvOyJ8JpSfjAU74W_XyCis-BCrk9IXGbc43g53XdvLFHeWFAjeB1nGceHHLP3HoJENEJnFJYZqZoz-Cu-yfA3G-P5euiz5E6lnmZqcZhxx-YSOtQCR6yaEwLPo4sGiZcjbxiumKT5NSCb7lC39nCbmW0DL_mc_2YrRp5cFYkvqn5Mu0BddgCrv8SkagqR_Smmd_2G3TlcNAIOf5jdVPs_hbLRPTw4g09GIt8xSjjdgGLDI7ev27c7UyNSlIgcTUsO2EBafJDgIhZE7LAH9ABFOukcA-A8fMEhDP-eAc2X59ECtX1CpPsLzE' 
            } })
            .then( (response) => {
                Swal.close();
                console.log('/assets/img2/insignias/' + this.file4.name)
                if (response.data.status == true) {
                    let model4 = {
                        sImagePath: '/assets/img2/insignias/' + this.file4.name,
                        sImageType: 'BadgeOff',
                        idExamPool: this.idExamPool
                    }
                    axios.post(apiURL + 'Exam/updateImagePath', model4, { headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.userToken
                    } })
                    .then( (response) => {
                        Swal.close();
                        if(response.data.bIsCorrect == false){
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.data.sErrors[0],
                                footer: ''
                            })
                            return
                        }
                        Swal.fire('Datos Guardados correctamente')
                        this.getData()
                    } )
                    .catch( (error) => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error,
                            footer: ''
                        })
                    } )                    
                    //this.model.sBadgeOff = '/assets/img2/insignias/' + this.file4.name
                    Swal.fire('Imagen de Insignia Inactiva Procesada Correctamente!!')
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        footer: ''
                    })
                }
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )                        
        },        
    },
    mounted() {
        this.getData()
    }    
        
}
</script>